import { useEffect } from 'react'

import getTopic from '../lib/topic'

export default function useColor(category, status) {
  const { background, promoted, illustration, dark, light } = getTopic(category?.topicTheme)

  useEffect(() => {
    const root = document.documentElement

    if (['entering', 'entered'].indexOf(status) > -1 || !status) {
      if (background && promoted) {
        root.style.setProperty('--color-dark', dark)
        root.style.setProperty('--color-promoted', promoted)
        root.style.setProperty('--color-background', background)
        root.style.setProperty('--color-light', light)
      } else {
        root.style.removeProperty('--color-dark')
        root.style.removeProperty('--color-promoted')
        root.style.removeProperty('--color-background')
        root.style.removeProperty('--color-light')
      }
    } else if (['exiting', 'exited'].indexOf(status) > -1) {
      if (background && promoted) {
        root.style.setProperty('--color-previous-dark', dark)
        root.style.setProperty('--color-previous-promoted', promoted)
        root.style.setProperty('--color-previous-background', background)
        root.style.setProperty('--color-previous-light', light)
      } else {
        root.style.removeProperty('--color-previous-dark')
        root.style.removeProperty('--color-previous-promoted')
        root.style.removeProperty('--color-previous-background')
        root.style.removeProperty('--color-previous-light')
      }
    }
  }, [status, background, dark, light, promoted])

  return {
    background,
    promoted,
    dark,
    light,
    illustration,
  }
}
