import { get } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

import { getImageUrl } from '../../api/sanity'
import { formatDate } from '../../helpers/date'
import Button from '../Button'

import BEMHelper from '@/helpers/bem'
import { SanityImageObject } from '@sanity/image-url/lib/types/types'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

type Article = {
  slug: string
  title: string
  preamble: string
  publishedAt?: string
  date?: string
  source?: string
  _id: string
  _type: string
  image: (SanityImageObject & { alt?: string; hasAlpha?: boolean }) | string
  loanArticle: string
  loanArticleSource: string
}

interface Props {
  articles: Article[]
  promoted?: boolean
  urlBuilder?: (slug: string) => string
}

export default function ArticleEntries({
  articles = [],
  promoted,
  urlBuilder = (slug: string) => `/artikkel/${slug}`,
}: Props) {
  const [showMore, setShowMore] = useState(false)

  if (!articles?.length) {
    return null
  }

  const displayedItems = showMore ? articles : [...articles.slice(0, 3)]
  const remainingItems = articles.length - displayedItems.length

  return (
    <nav {...bem('')}>
      <div {...bem('list')}>
        {displayedItems.map(
          ({
            _id,
            title,
            image,
            source,
            publishedAt,
            date,
            loanArticle,
            slug,
            loanArticleSource,
          }) => {
            const src =
              image && typeof image === 'object' && 'asset' in image
                ? getImageUrl(image).width(600).height(400).url()
                : image
            const published = publishedAt || date
            const illustration =
              promoted && get(image, 'hasAlpha') ? getImageUrl(image).width(600).url() : undefined

            return (
              <Link
                key={_id || slug}
                href={urlBuilder(slug)}
                {...bem('item', { 'no-image': !src, promoted })}
              >
                {src && !illustration && (
                  <Image
                    {...bem('image')}
                    src={src}
                    alt={typeof image === 'object' && 'alt' in image && image.alt ? image.alt : ''}
                    width={300}
                    height={200}
                  />
                )}
                {/* eslint-disable-next-line @next/next/no-img-element */}
                {illustration && <img src={illustration} alt="" {...bem('illustration')} />}

                <div {...bem('content')}>
                  <h3 {...bem('sub-title')}>{title}</h3>
                  {(published || source) && (
                    <span {...bem('meta')}>
                      {source ||
                        (loanArticle && loanArticleSource && `${loanArticleSource}, `) ||
                        (published && 'Tenk.') ||
                        ''}{' '}
                      {published && '- ' + formatDate(published)}
                    </span>
                  )}
                </div>
              </Link>
            )
          },
        )}
      </div>

      {articles.length > 3 && !showMore && (
        <Button onClick={() => setShowMore(true)} more>
          {remainingItems} flere
        </Button>
      )}
    </nav>
  )
}
