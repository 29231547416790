import { useEffect, useState } from 'react'

import { getElementClosestToViewport } from '../helpers/position'

let observer = null

export default function useFocus(list = []) {
  const [activeId, setActiveId] = useState(null)
  const [activeMainId, setActiveMainId] = useState(null)

  useEffect(() => {
    if (observer) {
      observer.disconnect()
    }

    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(() => {
          const closest = getElementClosestToViewport(elements)

          if (closest?.mainId === closest?.id) {
            const firstChild = closest?.element.querySelector(`[data-main-id="${closest.mainId}"]`)

            setActiveId(firstChild?.getAttribute('id') || closest?.id)
          } else {
            setActiveId(closest?.id)
          }

          setActiveMainId(closest?.mainId)
        })
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        // rootMargin: '-200px 0px',
      },
    )

    const ids = ['cover', ...list, 'footer']
    const elements = ids.map((id) => document.getElementById(id) || null).filter(Boolean)

    elements.forEach((section) => {
      if (section) {
        observer.observe(section)
      } else {
        console.warn('No element selected', section)
      }
    })

    return () => observer.disconnect()
  }, [list])

  return {
    activeSection: activeId,
    activeMainSection: activeMainId,
  }
}

export function useFocusSimple(list = []) {
  const [activeId, setActiveId] = useState(null)

  useEffect(() => {
    if (observer) {
      observer.disconnect()
    }

    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(() => {
          const closest = getElementClosestToViewport(elements)
          setActiveId(closest?.id)
        })
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        // rootMargin: '-200px 0px',
      },
    )

    const ids = [...list, 'footer']
    const elements = ids.map((id) => document.getElementById(id) || null).filter(Boolean)

    elements.forEach((section) => {
      if (section) {
        observer.observe(section)
      } else {
        console.warn('No element selected', section)
      }
    })

    return () => observer.disconnect()
  }, [list])

  return activeId
}
