import { orderBy } from 'lodash'

export function getCenterOfElement(element) {
  const top = element.getBoundingClientRect().top
  const height = element.offsetHeight

  return top + height / 2
}

export function calculateDistance(a, b) {
  const v = a - b
  return Math.sqrt(v * v)
}

export function getElementClosestToViewport(elements) {
  if (!elements.length) {
    return null
  }

  const windowCenter = window.innerHeight / 2

  const list = orderBy(
    elements.map((element) => {
      const pos = getCenterOfElement(element)

      const mainId = element.getAttribute('data-main-id')
      const id = element.getAttribute('id')
      const distance = calculateDistance(windowCenter, pos)

      return {
        distance,
        element,
        id: id,
        mainId: mainId || id,
      }
    }),
    'distance',
  )

  return list[0]
}
