export default function getTopic(theme) {
  return theme?.illustration?.path
    ? theme
    : {
        light: '#E8F3FA',
        background: '#D3E8F6',
        promoted: '#B1D6EE',
        dark: '#90C4E6',
        illustration: {
          path: '/illustrations/tenk.png',
          webp: '/illustrations/tenk.webp',
          aspect: 1200 / 1400,
        },
      }
}
