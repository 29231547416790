import React from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

export default function Section({
  title,
  preamble,
  children,
  type,
  spaced,
  ...props
}: {
  children: React.ReactNode
  title: string
  preamble?: React.ReactNode
  type?: 'grid' | 'stacked' | 'list'
  spaced?: boolean
} & React.HTMLAttributes<HTMLElement>) {
  if (title && preamble) {
    return (
      <section {...props} {...bem('', { spaced, ...(type ? { [type]: type } : {}) })}>
        <header {...bem('header')}>
          <h2 {...bem('title', 'spaceless')}>{title}</h2>
          <div {...bem('preamble')}>{preamble}</div>
        </header>
        <div {...bem('content')}>{children}</div>
      </section>
    )
  }

  return (
    <section {...props} {...bem('', { spaced, ...(type ? { [type]: type } : {}) })}>
      <h2 {...bem('title')}>{title}</h2>
      <div {...bem('content')}>{children}</div>
    </section>
  )
}
