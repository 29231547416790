import Image from 'next/image'
import Link from 'next/link'
import { useRef, useState } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

type VideoType = {
  // Define the properties of the VideoType here
  // For example:
  url: string
  duration: number
  asset: {
    playbackId: string
  }
  // Add more properties as needed
}

type EntryType = {
  _id?: string
  objectID?: string
  slug: string
  title: string
  youtubeId?: string
  video?: VideoType
  caption?: string
  titleNode?: 'h2' | 'h3'
  square?: boolean
  className?: string
}

interface Props {
  title?: string
  id?: string
  items: Array<EntryType>
}

export default function VideoEntries({ items = [], title, id }: Props) {
  const [showMore, setShowMore] = useState(false)
  const entriesRef = useRef<HTMLDivElement>(null)

  if (!items?.length) {
    return null
  }

  const displayedItems = showMore ? items : [...items.slice(0, 3)]
  const remainingItems = items.length - displayedItems.length

  return (
    <nav {...bem('')} id={id} ref={entriesRef}>
      {title && <h2 {...bem('main-title')}>{title}</h2>}

      <ul {...bem('list')}>
        {displayedItems.map((item) => (
          <Entry key={item._id || item.objectID || item.slug} {...item} />
        ))}
      </ul>

      {items.length > 3 && !showMore && (
        <Button onClick={() => setShowMore(true)} more>
          {remainingItems} flere
        </Button>
      )}
    </nav>
  )
}

export function Entry(props: EntryType) {
  const { slug, title, className, titleNode, square, youtubeId, video, caption } = props
  const Title = titleNode || 'h2'

  const hasLongWord = title.split(' ').some((str) => str.length > 16)

  return (
    <li {...bem('item', '', className)}>
      <Link href={`/video/${slug}`} {...bem('link', { square })}>
        <span {...bem('play')}>
          <Icon icon="play" />
        </span>

        {youtubeId && !video && (
          <>
            <div {...bem('overlay')}></div>
            <Image
              {...bem('image')}
              src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`}
              fill
              alt={caption || title || ''}
            />
          </>
        )}
        {video && (
          <>
            <div {...bem('overlay')}></div>
            <Image
              {...bem('image')}
              src={`https://image.mux.com/${video?.asset?.playbackId}/thumbnail.jpg`}
              fill
              alt={caption || title || ''}
            />
          </>
        )}
        <div {...bem('content')}>
          <span {...bem('tag')}>Video</span>
          <Title {...bem('title', { 'long-word': hasLongWord })}>{title}</Title>
        </div>
      </Link>
    </li>
  )
}
