import React from 'react'
import T from 'prop-types'
import { sortBy } from 'lodash'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

const TYPES = {
  diskusjon: { order: 3, title: 'Diskusjon', src: '/icons/diskusjon', aspect: 1083 / 1067 },
  submission: {
    order: 1,
    title: 'Tekstarbeid',
    src: '/icons/innlevering',
    aspect: 1063 / 1175,
  },
  home: { order: 1, title: 'Hjemmeskole', src: '/icons/hjemmeskole', aspect: 1214 / 1100 },
  quiz: { order: 1, title: 'Quiz', src: '/icons/quiz', aspect: 1035 / 1034 },
  game: { order: 3, title: 'Spill', src: '/icons/spill', aspect: 1160 / 988 },
  video: { order: 4, title: 'Video', src: '/icons/video', aspect: 1264 / 856 },
}

export default function IllustrationGrid({ types = {}, className, hover, large }) {
  const list = sortBy(
    Object.keys(types)
      .filter((key) => types[key] && TYPES[key])
      .filter((_, index) => index < 3)
      .map((key) => ({ key, ...TYPES[key] })),
    ['order'],
  )

  const size = large ? 400 : 400 // TODO: Should be different, but then it feels slow when you go from entry to cover

  return (
    <figure {...bem('', { hover }, className)}>
      <svg viewBox="0 0 1 1" {...bem('aspect')} />
      <div {...bem('images', list.length)}>
        {list.map(({ key, title, src, aspect }, index) => (
          <div key={key} {...bem('cell', index + 1)}>
            <div {...bem('image', [index + 1, key])}>
              <picture>
                <source srcSet={`${src}.webp`} type="image/webp" />
                <source srcSet={`${src}.png`} type="image/png" />
                <img src={`${src}.png`} alt={title} width={size} height={size / aspect} />
              </picture>
            </div>
          </div>
        ))}
      </div>
    </figure>
  )
}

IllustrationGrid.propTypes = {
  types: T.object.isRequired,
  className: T.string,
  hover: T.bool,
  large: T.bool,
}
